<template>
  <div class="row d-flex">
    <div class="col">
      <complete-displayer-preview
        @addItem="addItem"
      ></complete-displayer-preview>
      <grid-layout
        :layout.sync="layout"
        :col-num="colNum"
        :row-height="75"
        :is-draggable="true"
        :is-resizable="true"
        :use-css-transforms="true"
      >
        <grid-item
          v-for="item in layout"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :key="item.i"
          :minH="item.configuration.minH"
          :minW="item.configuration.minW"
          :maxH="item.configuration.maxH"
          :maxW="item.configuration.maxW"
          @moved="itemMoved()"
        >
          <signal
            :deviceSlot="item.item"
            :h="item.h"
            :w="item.w"
            v-if="item.item.displayModeId == 7"
            :isClickable="false"
          ></signal>
          <number
            :deviceSlot="item.item"
            :h="item.h"
            :w="item.w"
            v-if="item.item.displayModeId == 5 || item.item.displayModeId == 4"
            :isClickable="false"
          ></number>
          <battery
            :deviceSlot="item.item"
            :h="item.h"
            :w="item.w"
            v-if="item.item.displayModeId == 6"
            :isClickable="false"
          ></battery>
          <slot-graph
            :interval="{ start: dateFilter[0], end: dateFilter[1] }"
            :deviceSlot="item.item"
            :h="item.h"
            :w="item.w"
            v-if="item.item.displayModeId == 1"
            :isClickable="!editable"
          ></slot-graph>
          <span class="remove" @click="removeItem(item.i)">x</span>
        </grid-item>
      </grid-layout>
    </div>
  </div>
</template>

<script>
import VueGridLayout from "vue-grid-layout";
import Number from "@/components/DynamicDashboard/Displayers/Number";
import Battery from "@/components/DynamicDashboard/Displayers/Battery";
import Signal from "@/components/DynamicDashboard/Displayers/Signal";

//______________________________________________
import SlotGraph from "@/components/DynamicDashboard/Displayers/graphs/SlotGraph.vue";
//_____________________________________________

import CompleteDisplayerPreview from "@/components/DynamicDashboard/DisplayerPreview/CompleteDisplayerPreview";

export default {
  name: "DynamicDashboard",
  components: {
    Number,
    Battery,
    Signal,
    SlotGraph,
    CompleteDisplayerPreview,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  props: {
    dashboard: Object,
    sendData: Boolean,
  },
  data() {
    return {
      layout: [],
      colNum: 10,
      index: 0,
      dateFilter: [],
      content: [],
    };
  },
  mounted() {
    // this.$gridlayout.load();
    this.layout = this.dashboard.content;
    this.index = this.layout.length;
    this.set48HrsDefault();
  },
  watch: {
    dashboard() {
      this.initDashboard();
    },
  },
  methods: {
    addItem: function (item) {
      let minW;
      let maxH;
      let minH;
      let maxW;
      switch (item.displayModeId) {
        case 1:
          minH = 2;
          minW = 2;
          maxH = 4;
          maxW = 5;
          break;
        case 2:
          minH = 2;
          minW = 2;
          maxH = 4;
          maxW = 5;
          break;
        case 5:
          minH = 1;
          minW = 2;
          maxH = 3;
          maxW = 3;
          break;
        case 6:
          minH = 1;
          minW = 2;
          maxH = 3;
          maxW = 3;
          break;
        case 7:
          minH = 1;
          minW = 2;
          maxH = 3;
          maxW = 3;
          break;
        default:
          minH = 1;
          minW = 2;
          maxH = 3;
          maxW = 3;
          break;
      }
      // Add a new item. It must have a unique key!
      this.layout.push({
        x: (this.layout.length * 2) % (this.colNum || 12),
        y: this.layout.length + (this.colNum || 12), // puts it at the bottom
        w: 2,
        h: 2,
        i: this.index,
        item: item,
        configuration: {
          minH,
          minW,
          maxH,
          maxW,
        },
      });
      // Increment the counter to ensure key is always unique.
      this.index++;
      this.setPutBodyContent();
      this.$emit("dashboardContent", this.content);
    },
    removeItem: function (val) {
      const index = this.layout.map((item) => item.i).indexOf(val);
      this.layout.splice(index, 1);
      this.setPutBodyContent();
      this.$emit("dashboardContent", this.content);
    },
    setPutBodyContent() {
      this.content = this.layout.map((item) => {
        const newItem = {
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h,
          i: item.i,
          item: {
            id: item.item.id,
            entity: "displayer",
          },
        };
        return newItem;
      });
    },
    initDashboard() {
      this.layout = this.dashboard.content;
      this.setIndex();
    },
    setIndex() {
      if (this.layout.length == 0) {
        this.index = 0;
      } else {
        this.index = this.layout[this.layout.length - 1].i + 1;
      }
    },
    set48HrsDefault() {
      let from = new Date(Date.now() - 2 * 24 * 60 * 60 * 1000);
      let to = new Date();

      this.dateFilter = [from, to];
    },
    itemMoved() {
      this.setPutBodyContent();
      this.$emit("dashboardContent", this.content);
    },
  },
};
</script>
<style >
.save-button-container {
  width: 100%;
  margin: 10px 0 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.layoutJSON {
  background: #ddd;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}
.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}
/*************************************/
.remove {
  position: absolute;
  right: 2px;
  top: 0;
  cursor: pointer;
}
.vue-grid-layout {
  background: white;
}
.vue-grid-item .resizing {
  opacity: 0.9;
}
.vue-grid-item .static {
  background: #cce;
}
.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}
.vue-grid-item .add {
  cursor: pointer;
}
.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>")
    no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>