import { render, staticRenderFns } from "./DynamicDashboardCreate.vue?vue&type=template&id=dfa1deda"
import script from "./DynamicDashboardCreate.vue?vue&type=script&lang=js"
export * from "./DynamicDashboardCreate.vue?vue&type=script&lang=js"
import style0 from "./DynamicDashboardCreate.vue?vue&type=style&index=0&id=dfa1deda&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports