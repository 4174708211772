<template>
  <md-card>
    <md-card-header :data-background-color="getTheme">
      <h4 class="title">
        {{
          $route.params.dashboard
            ? $t("workspace.dashboard.dashboardForm.titleFormEdit")
            : $t("workspace.dashboard.dashboardForm.titleFormCreate")
        }}
      </h4>
    </md-card-header>
    <md-card-content>
      <div class="container-fluid">
        <v-form ref="form">
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <TGCTextField
                    :label="$t('workspace.dashboard.dashboardForm.name')"
                    v-model="form.name"
                    :rules="[
                      rules.required,
                      (value) => rules.minLength(value, 0),
                      (value) => rules.maxLength(value, 100),
                    ]"
                  />
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.dashboardForm.name") }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="row d-flex align-center m-0 mb-5">
                <div class="col col-10">
                  <v-textarea
                    outlined
                    name="input-7-4"
                    :label="$t('workspace.dashboard.dashboardForm.description')"
                    v-model="form.description"
                  ></v-textarea>
                </div>
                <v-tooltip bottom open-delay="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      size="20"
                      class="col col-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline</v-icon
                    >
                  </template>
                  <span>{{ $t("tooltips.dashboardForm.description") }}</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </v-form>
        <md-card>
          <md-card-header
            :data-background-color="getTheme"
            class="row d-flex justify-content-between align-items-center"
          >
            <h4 class="title">
              {{
                form.name != ""
                  ? form.name
                  : $t("workspace.dashboard.dashboardForm.customDashboard")
              }}
            </h4>
          </md-card-header>
          <md-card-content>
            <dynamic-dashboard-create
              @dashboardContent="setContent"
              :dashboard="dashboard"
            ></dynamic-dashboard-create>
          </md-card-content>
        </md-card>
        <div class="row">
          <div class="col-12 text-right">
            <tgl-save-button
              ref="saveBtn"
              :entity="entity"
              :dispatch="entity.dispatch"
              :action="editMode ? 'editado' : 'creado'"
              :redirect="entity.redirect"
              :validateForm="handleSubmit"
            ></tgl-save-button>
          </div>
        </div>
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
import form from "@/mixins/form";
import DynamicDashboardCreate from "./DynamicDashboardCreate.vue";
import { mapGetters } from "vuex";
import TglSaveButton from "@/components/UI/TglSaveButton.vue";
import TGCTextField from "@/components/UI/TGCTextField";

export default {
  components: { DynamicDashboardCreate, TglSaveButton, TGCTextField },
  name: "DynamicDashboardForm",
  mixins: [form],
  props: {
    dataBackgroundColor: {
      type: String,
      default: "ow",
    },
  },
  data: () => ({
    form: {
      name: "",
      scopeId: 1,
      description: "",
    },
    id: 0,
    content: [],
    editMode: false,
    listenerTypes: [],
    dashboard: {
      name: "",
      content: [],
      description: "",
    },
  }),
  mounted() {
    if (this.$route.params.dashboard) {
      this.editMode = true;
      this.getDashboard();
    }
  },
  computed: {
    ...mapGetters(["getTheme"]),
    entity() {
      let params = {
        name: this.form.name,
        scopeId: this.form.scopeId,
        description: this.form.description,
        content: this.content,
      };
      return {
        name: "tablero",
        params: this.editMode
          ? { ...params, id: this.$route.params.dashboard }
          : params,
        dispatch: this.editMode
          ? "dashboard/editDashboard"
          : "dashboard/createDashboard",
        redirect: { name: "workspace", params: { tab: 0 } },
      };
    },
  },
  methods: {
    handleSubmit() {
      return this.$refs.form.validate();
    },
    getDashboard() {
      this.$store
        .dispatch("dashboard/getDashboard", this.$route.params.dashboard)
        .then((data) => {
          if (data) {
            this.dashboard = data;
            this.content = this.setPutBodyContent(data.content);
            this.form.name = data.name;
            this.form.description = data.description;
          }
        })
        .catch((err) => console.error(err));
    },
    setContent(dashboardContent) {
      this.content = dashboardContent;
    },
    setPutBodyContent(content) {
      return content.map((item) => {
        const newItem = {
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h,
          i: item.i,
          item: {
            id: item.item.id,
            entity: "displayer",
          },
        };
        return newItem;
      });
    },
  },
};
</script>